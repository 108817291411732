<template>
  <!--begin::Stepper-->
  <div
    class="
      stepper stepper-pills stepper-column
      d-flex
      flex-column flex-xl-row flex-row-fluid
    "
    id="kt_create_account_stepper"
    ref="verticalWizardRef"
  >
    <!--begin::Aside-->
    <div
      class="
        d-flex
        justify-content-center
        bg-white
        rounded
        justify-content-xl-start
        flex-row-auto
        w-100 w-xl-300px w-xxl-400px
        me-9
      "
    >
      <!--begin::Wrapper-->
      <div class="px-6 px-lg-10 px-xxl-15 py-20">
        <!--begin::Nav-->
        <div class="stepper-nav">
          <!--begin::Step 1-->
          <div class="stepper-item current" data-kt-stepper-element="nav">
            <!--begin::Line-->
            <div class="stepper-line w-40px"></div>
            <!--end::Line-->

            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">1</span>
            </div>
            <!--end::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title"> {{ $t("modals.interview.createJobPostingWizard.chooseJobPosting") }}</h3>

              <div class="stepper-desc fw-bold">{{ $t("modals.interview.createJobPostingWizard.chooseJobPostingDesc") }}</div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Step 1-->

          <!--begin::Step 2-->
          <div class="stepper-item" data-kt-stepper-element="nav">
            <!--begin::Line-->
            <div class="stepper-line w-40px"></div>
            <!--end::Line-->

            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">2</span>
            </div>
            <!--end::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title">{{ $t("modals.interview.createJobPostingWizard.createInterview") }}</h3>

              <div class="stepper-desc fw-bold">
                {{ $t("modals.interview.createJobPostingWizard.createInterviewDesc") }}
              </div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Step 2-->

          <!--begin::Step 3-->
          <div class="stepper-item" data-kt-stepper-element="nav">
            <!--begin::Line-->
            <div class="stepper-line w-40px"></div>
            <!--end::Line-->

            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">3</span>
            </div>
            <!--end::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title">{{ $t("modals.interview.createJobPostingWizard.setQuestions") }}</h3>
              <div class="stepper-desc fw-bold">
                {{ $t("modals.interview.createJobPostingWizard.setQuestionsDesc") }}
              </div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Step 3-->

          <!--begin::Step 4-->
          <div class="stepper-item" data-kt-stepper-element="nav">
            <!--begin::Line-->
            <div class="stepper-line w-40px"></div>
            <!--end::Line-->

            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">4</span>
            </div>
            <!--end::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title">{{ $t("modals.interview.createJobPostingWizard.sortInterviews") }}</h3>
              <div class="stepper-desc fw-bold">
                {{ $t("modals.interview.createJobPostingWizard.sortInterviewsDesc") }}
              </div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Step 4-->
          <!--begin::Step 5-->
          <div class="stepper-item" data-kt-stepper-element="nav">
            <!--begin::Line-->
            <div class="stepper-line w-40px"></div>
            <!--end::Line-->

            <!--begin::Icon-->
            <div class="stepper-icon w-40px h-40px">
              <i class="stepper-check fas fa-check"></i>
              <span class="stepper-number">5</span>
            </div>
            <!--end::Icon-->

            <!--begin::Label-->
            <div class="stepper-label">
              <h3 class="stepper-title">{{ $t("modals.interview.createJobPostingWizard.createdInterview") }}</h3>
              <div class="stepper-desc fw-bold">{{ $t("modals.interview.createJobPostingWizard.createdInterviewDesc") }}</div>
            </div>
            <!--end::Label-->
          </div>
          <!--end::Step 5-->
        </div>
        <!--end::Nav-->
      </div>
      <!--end::Wrapper-->
    </div>
    <!--begin::Aside-->

    <!--begin::Content-->
    <div
      class="
        d-flex
        justify-content-center
        align-items-center
        w-100
        h-100
        bg-light
      "
    >
      <div
        class="card w-100 shadow-sm"
        style="overflow-y: auto; display: flex; flex-direction: column"
      >
        <form
          id="kt_create_account_form"
          @submit.prevent="handleStep"
          class="d-flex flex-column flex-grow-1"
        >
          <!--begin::Card Body-->
          <div
            class="
              card-body
              flex-grow-1
              d-flex
              flex-column
              pt-10
              me-10
              mb-0
              ms-10
              pb-5
              pt-5
            "
            style="height: 60vh; overflow-y: auto"
          >
            <!--stepper içeriği burada dönecek-->
            <!--begin::Step 1-->
            <div class="pt-10 current" data-kt-stepper-element="content">
              <Step1 @updateJobPosting="updateJobPosting" />
            </div>
            <!--end::Step 1-->

            <!--begin::Step 2-->
            <div class="pt-10" data-kt-stepper-element="content">
              <Step2 @updateTitleDescription="updateTitleDescription" />
            </div>
            <!--end::Step 2-->

            <!--begin::Step 3-->
            <div class="pt-10" data-kt-stepper-element="content">
              <Step3 @updateQuestions="updateQuestions" />
            </div>
            <!--end::Step 3-->

            <!--begin::Step 4-->
            <div class="pt-10" data-kt-stepper-element="content">
              <Step4
                :jobPostingId="createInterviewModel.jobPosting"
                :interviewTitle="createInterviewModel.title"
                :interviewDesc="createInterviewModel.description"
                @updateSequences="updateSequences"
              />
            </div>
            <!--end::Step 4-->

            <!--begin::Step 5-->
            <div class="pt-10" data-kt-stepper-element="content">
              <Step5 @complete="submitForm" />
            </div>
            <!--end::Step 5-->
          </div>
          <!--end::Card Body-->

          <!--begin::Card Footer-->
          <div
            v-if="currentStepIndex != totalSteps - 1"
            class="
              card-footer
              d-flex
              justify-content-between
              align-items-center
            "
            style="
              background-color: #0e3a57;
              border-top: 2px solid #e5e5e5;
              margin-top: auto;
            "
          >
            <!-- Geri Butonu -->
            <div v-if="currentStepIndex != totalSteps - 1" class="mr-2">
              <button
                type="button"
                class="btn btn-lg btn-light-primary me-3"
                data-kt-stepper-action="previous"
                @click="previousStep"
              >
                <span class="svg-icon svg-icon-4 me-1">
                  <inline-svg src="media/icons/duotune/arrows/arr063.svg" />
                </span>
                {{
                  $t("common.button.back")
                }}
              </button>
            </div>

            <!-- Devam veya Oluştur Butonu -->
            <div class="d-flex justify-content-end ms-auto">
              <!-- Oluştur Butonu -->
              <button
                type="button"
                class="btn btn-lg btn-primary me-3"
                data-kt-stepper-action="submit"
                v-if="currentStepIndex == totalSteps - 1"
                @click="formSubmit()"
              >
                <span class="indicator-label">
                  {{
                  $t("common.button.create")
                }}
                  <span class="svg-icon svg-icon-3 ms-2 me-0">
                    <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                  </span>
                </span>
                <span class="indicator-progress">
                  {{
                  $t("common.pleaseWait")
                }}
                  <span
                    class="spinner-border spinner-border-sm align-middle ms-2"
                  ></span>
                </span>
              </button>

              <!-- Devam Butonu -->
              <button v-else-if="currentStepIndex!= 2 || (currentStepIndex == 2 && createInterviewModel.interviewQuestions && createInterviewModel.interviewQuestions.length > 2)" type="submit" class="btn btn-lg btn-primary">
                                  {{
                  $t("common.button.continue")
                }}
                <span class="svg-icon svg-icon-4 ms-1 me-0">
                  <inline-svg src="media/icons/duotune/arrows/arr064.svg" />
                </span>
              </button>
            </div>
          </div>
          <!--end::Card Footer-->
        </form>
      </div>
    </div>
    <!--end::Content-->
  </div>
  <!--end::Stepper-->
</template>

<script lang="ts">
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { StepperComponent } from "@/presentation/assets/ts/components";
import { useI18n } from "vue-i18n";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
import { CreateInterviewModel } from "@/domain/interviewer/create-interview/model/CreateInterviewModel";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

interface IStep1 {
  jobPostingId: number;
}

interface IStep2 {
  interviewTitle: string;
  interviewDesc: string;
}

interface IStep3 {
  interviewQuestions: InterviewQuestionModel[];
}

interface CreateInterview extends IStep1, IStep2, IStep3 {}

export default defineComponent({
  name: "kt-vertical-wizard",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  setup() {
    const { t } = useI18n();
    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const createInterviewModel = ref<CreateInterviewModel>({});
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();

    const formState = reactive({
      jobPosting: null,
      interviews: [],
    });

    const formData = ref<CreateInterview>({
      jobPostingId: 0,
      interviewTitle: "",
      interviewDesc: "",
      interviewQuestions: [],
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );
      setCurrentPageTitle(t("modals.interview.title"));
    });

    const createInterviewSchema = [
      Yup.object({
        jobPostingId: Yup.number()
          .required("İş ilanı seçilmesi zorunludur.")
          .notOneOf([0], "Lütfen iş ilanı seçiniz."),
      }),
      Yup.object({
        interviewTitle: Yup.string()
          .required(t("validators_error.required"))
          .label("İnterview Title"),
        interviewDesc: Yup.string()
          .required(t("validators_error.required"))
          .label("İnterview Description"),
      }),
    ];

    const currentSchema = computed(() => {
      return createInterviewSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<IStep1 | IStep2 | IStep3>({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (currentStepIndex.value == 4) {
        submitForm();
      }

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        heightAuto: false,
      }).then(() => {
        window.location.reload();
      });
    };

    const updateJobPosting = (jobPosting) => {
      createInterviewModel.value.jobPosting = jobPosting;
      store.dispatch("choosenJobPostingIdOnCreateInterviewPage", jobPosting);
      formState.jobPosting = jobPosting;
    };

    const updateTitleDescription = ({ title, description }) => {
      createInterviewModel.value.title = title;
      createInterviewModel.value.description = description;
    };

    const updateQuestions = (questions: InterviewQuestionModel[]) => {
      createInterviewModel.value.interviewQuestions = questions;
      formData.value.interviewQuestions = questions;
    };

    const updateSequences = (sequences: InterviewSequenceModel[]) => {
      createInterviewModel.value.interviewSequences = sequences;
    };

    const submitForm = () => {
      interviewerController
        .createInterview(createInterviewModel.value)
        .then((response) => {
          if (response.isSuccess) {
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      verticalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      updateJobPosting,
      updateTitleDescription,
      updateQuestions,
      updateSequences,
      submitForm,
      createInterviewModel,
      formData,
    };
  },
});
</script>
