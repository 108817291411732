
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { Field, ErrorMessage } from "vee-validate";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";

export default defineComponent({
  name: "step-3",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const showModal = ref(false);
    const swalNotification = new SwalNotification();
    const { t } = useI18n();

    const newQuestion = ref({
      text: "",
      type: QuestionTypes.OPEN_ENDED,
      options: [] as string[],
    });

    const newOptionText = ref("");
    const interviewQuestions = ref<InterviewQuestionModel[]>([]);

    const addQuestion = () => {
      if (!newQuestion.value.text) {
        swalNotification.error(
          t("modals.interview.pleaseWriteQuestion"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } else if (
        newQuestion.value.type == QuestionTypes.SINGLE_CHOICE ||
        newQuestion.value.type == QuestionTypes.MULTIPLE_CHOICE
      ) {
        if (newQuestion.value.options.length == 0) {
          swalNotification.error(
            t("modals.interview.pleaseAddOptions"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          return;
        } else if (newQuestion.value.options.length < 3) {
          swalNotification.error(
            t("modals.interview.min3Options"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          return;
        }
      }

      if (newQuestion.value.text.trim() !== "") {
        const question = new InterviewQuestionModel(
          newQuestion.value.options.map((option) => ({
            id: 0,
            optionText: option,
          })),
          0,
          newQuestion.value.text,
          newQuestion.value.type
        );
        interviewQuestions.value.push(question);
        newQuestion.value = {
          text: "",
          type: QuestionTypes.OPEN_ENDED,
          options: [],
        };
        showModal.value = false;
        emit("updateQuestions", interviewQuestions.value);
      }
    };

    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      interviewQuestions.value.splice(index, 1);
      emit("updateQuestions", interviewQuestions.value);
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      showModal,
      newQuestion,
      newOptionText,
      interviewQuestions,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      closeModal,
      QuestionTypes,
    };
  },
});
