
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import store from "@/presentation/store";
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";

export default defineComponent({
  name: "step-4",
  props: {
    jobPostingId: {
      type: Number,
      required: false,
    },
    interviewTitle: {
      type: String,
      required: false,
    },
    interviewDesc: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const interviewSequence = ref<InterviewSequenceModel[]>();
    const jobPostingId = ref<number| undefined>(props.jobPostingId);
      const interviewTitle = computed(() => props.interviewTitle);
      const interviewDesc = computed(() => props.interviewDesc);

    const jobPosting = ref<JobPostingDetailModel>({
    });

    const interviews = ref<JobPostingInterviewModel[]>([]);

    const newInterview = ref({
    title: interviewTitle.value,
    description: interviewDesc.value,
    sequence: 0,
  });

  watch([interviewTitle, interviewDesc], () => {
    newInterview.value = {
      title: interviewTitle.value,
      description: interviewDesc.value,
      sequence: interviews.value.length, 
    };
    getJobPosting()

  });

    const getJobPosting = () => {
      interviews.value = []
      if(jobPostingId.value != 0 && jobPostingId.value != undefined && jobPostingId.value){
      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue())
            if(jobPosting.value.interviews)
        
            Object.assign(interviews.value, jobPosting.value.interviews);
            newInterview.value.sequence = interviews.value.length;

            interviews.value.push(newInterview.value);

            interviewSequence.value = interviews.value.map(
              (interview, idx) =>
                new InterviewSequenceModel(interview.id ?? null, idx + 1)
            );

            emit("updateSequences", interviewSequence);
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
      }
    };

    watch(
      () => store.getters.choosenJobPostingIdOnCreateInterviewPage,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) {
          jobPostingId.value = Number(newValue);
          getJobPosting();
        }
      },
      { immediate: true }
    );

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
        const updatedSequences = interviews.value.map((interview, idx) => {
          return new InterviewSequenceModel(interview.id, idx + 1);
        });

        // Emit edilen model
        emit("updateSequences", updatedSequences);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    return {
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
      jobPosting,
      newInterview,
    };
  },
});
