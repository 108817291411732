<template>
  <!--begin::Wrapper-->
  <div class="w-100 text-center py-10">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <!--begin::Title-->
      <h2 class="fw-bolder text-dark"> {{ $t("modals.interview.createJobPostingWizard.createdInterview") }}</h2>
      <!--end::Title-->

      <!--begin::Notice-->
      <div class="text-gray-400 fw-bold fs-6">
        {{ $t("modals.interview.createJobPostingWizard.successCreatedInterview") }}
      </div>
      <!--end::Notice-->
    </div>
    <!--end::Heading-->

    <!--begin::Message-->
    <div class="text-gray-700 fw-semibold fs-5 my-4">
      {{ $t("modals.interview.createJobPostingWizard.step5Desc") }}
    </div>

    <!--begin::Link to see all interviews-->
    <router-link
      to="/interviewer/jobposting-list"
      class="text-primary fw-bold fs-6"
    >
    {{ $t("modals.interview.createJobPostingWizard.seeAllJobpostings") }}
    </router-link>
    <!--end::Link-->
  </div>
  <!--end::Wrapper-->
</template>

<script lang="ts">
import { defineComponent, onMounted } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-5",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    return {  };
  },
});
</script>
