<template>
  <div class="w-100">
    <!-- Başlık -->
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">
        {{ $t("modals.interview.createJobPostingWizard.setQuestions") }}
      </h2>
      <div class="text-gray-400 fw-bold fs-6">
        {{ $t("modals.interview.createJobPostingWizard.defineQuestions") }}
      </div>
    </div>

    <div
      v-if="interviewQuestions.length < 3"
      class="
        notice
        d-flex
        bg-light-warning
        rounded
        border-warning border border-dashed
        mb-10
        p-6
      "
    >
      <div class="d-flex flex-stack flex-grow-1">
        <div class="fs-6 fw-bold">
          {{ $t("modals.interview.createJobPostingWizard.min3Questions") }}
        </div>
      </div>
    </div>

    <div class="mb-10 fv-row">
      <div class="text-center my-4">
        <button
          class="btn btn-primary mb-4"
          type="button"
          @click="showModal = true"
        >
          {{
            $t("modals.interview.createJobPostingWizard.addQuestionToInterview")
          }}
        </button>
      </div>
    </div>

    <!-- Soru Ekleme Butonu -->

    <div
      v-if="showModal"
      class="modal fade show"
      tabindex="-1"
      style="display: block; background: rgba(0, 0, 0, 0.5)"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">
              {{ $t("modals.interview.createJobPostingWizard.addQuestion") }}
            </h5>
            <button
              type="button"
              class="btn-close"
              @click="closeModal"
            ></button>
          </div>
          <div class="modal-body">
            <!-- Soru Yazma Inputu -->
            <div class="mb-3">
              <label class="form-label">{{
                $t("modals.interview.question")
              }}</label>
              <textarea
                class="form-control form-control-lg form-control-solid me-3"
                v-model="newQuestion.text"
                @keyup.enter="addQuestion"
                :placeholder="$t('modals.interview.writeQuestionPressEnter')"
                style="flex-grow: 1"
              ></textarea>
            </div>

            <!-- Soru Tipi Seçme -->
            <div class="mb-3">
              <label class="form-label">{{
                $t("modals.interview.questionType")
              }}</label>
              <select v-model="newQuestion.type" class="form-select">
                <option :value="QuestionTypes.OPEN_ENDED">
                  {{ $t("modals.interview.openEnded") }}
                </option>
                <option :value="QuestionTypes.SINGLE_CHOICE">
                  {{ $t("modals.interview.singleChoice") }}
                </option>
                <option :value="QuestionTypes.MULTIPLE_CHOICE">
                  {{ $t("modals.interview.multipleChoice") }}
                </option>
              </select>
            </div>

            <!-- Opsiyon Ekleme -->
            <div v-if="newQuestion.type !== 'OPEN_ENDED'" class="mb-3">
              <label class="form-label">{{
                $t("modals.interview.addOption")
              }}</label>
              <div class="d-flex">
                <input
                  type="text"
                  v-model="newOptionText"
                  @keyup.enter="addOption"
                  class="form-control me-3"
                  :placeholder="$t('modals.interview.forAddQualification')"
                  v-on:keydown.enter.prevent
                />
                <button
                  type="button"
                  class="btn btn-secondary ms-2"
                  @click="addOption"
                >
                  {{ $t("common.button.add") }}
                </button>
              </div>
              <ul
                v-if="newQuestion.options.length"
                class="list-group mt-2 mb-4"
              >
                <li
                  v-for="(option, index) in newQuestion.options"
                  :key="index"
                  class="list-group-item ps-5 pb-8"
                >
                  {{ option }}
                  <button
                    type="button"
                    @click="removeOption(index)"
                    class="btn btn-sm btn-danger delete-button"
                  >
                    {{ $t("common.button.delete") }}
                  </button>
                </li>
              </ul>
            </div>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" @click="closeModal">
              {{ $t("common.button.cancel") }}
            </button>
            <button type="button" class="btn btn-primary" @click="addQuestion">
              {{
                $t("modals.interview.createJobPostingWizard.addThisQuestion")
              }}
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- Eklenen Sorular -->
    <div v-if="interviewQuestions.length > 0" class="mt-5">
      <h3 class="form-label">
        {{ $t("modals.interview.createJobPostingWizard.addedQuestions") }}
      </h3>
      <ul class="list-group">
        <li
          v-for="(question, index) in interviewQuestions"
          :key="index"
          class="
            list-group-item
            d-flex
            justify-content-between
            align-items-start
          "
          style="position: relative"
        >
          <div>
            <!-- Soru başlığı -->
            <div class="question-text">
              <strong>{{ $t("modals.interview.question") }}:</strong>
              <span>{{ question.questionText }}</span>
            </div>

            <!-- Soru tipi -->
            <div class="question-type">
              <strong>{{ $t("modals.interview.questionType") }}:</strong>
              <span>
                {{
                  question.questionType === "OPEN_ENDED"
                    ? "Açık Uçlu"
                    : question.questionType === "SINGLE_CHOICE"
                    ? "Tek Seçenekli"
                    : "Çok Seçenekli"
                }}
              </span>
            </div>

            <!-- Seçenekler -->
            <ul
              v-if="question.interviewQuestionOptions.length > 0"
              class="mt-2"
            >
              <li
                v-for="(option, i) in question.interviewQuestionOptions"
                :key="i"
              >
                {{ i + 1 }}. {{ option.optionText }}
              </li>
            </ul>
          </div>

          <!-- Silme Butonu -->
          <button
            type="button"
            @click="removeQuestion(index)"
            class="btn btn-sm btn-danger delete-button"
          >
            {{ $t("common.button.delete") }}
          </button>
        </li>
      </ul>
    </div>
  </div>
</template>

<script lang="ts">
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { Field, ErrorMessage } from "vee-validate";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { QuestionTypes } from "@/domain/constants/QuestionTypes";

export default defineComponent({
  name: "step-3",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const showModal = ref(false);
    const swalNotification = new SwalNotification();
    const { t } = useI18n();

    const newQuestion = ref({
      text: "",
      type: QuestionTypes.OPEN_ENDED,
      options: [] as string[],
    });

    const newOptionText = ref("");
    const interviewQuestions = ref<InterviewQuestionModel[]>([]);

    const addQuestion = () => {
      if (!newQuestion.value.text) {
        swalNotification.error(
          t("modals.interview.pleaseWriteQuestion"),
          t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
        );
        return;
      } else if (
        newQuestion.value.type == QuestionTypes.SINGLE_CHOICE ||
        newQuestion.value.type == QuestionTypes.MULTIPLE_CHOICE
      ) {
        if (newQuestion.value.options.length == 0) {
          swalNotification.error(
            t("modals.interview.pleaseAddOptions"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          return;
        } else if (newQuestion.value.options.length < 3) {
          swalNotification.error(
            t("modals.interview.min3Options"),
            t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
          );
          return;
        }
      }

      if (newQuestion.value.text.trim() !== "") {
        const question = new InterviewQuestionModel(
          newQuestion.value.options.map((option) => ({
            id: 0,
            optionText: option,
          })),
          0,
          newQuestion.value.text,
          newQuestion.value.type
        );
        interviewQuestions.value.push(question);
        newQuestion.value = {
          text: "",
          type: QuestionTypes.OPEN_ENDED,
          options: [],
        };
        showModal.value = false;
        emit("updateQuestions", interviewQuestions.value);
      }
    };

    const addOption = () => {
      if (newOptionText.value.trim() !== "") {
        newQuestion.value.options.push(newOptionText.value.trim());
        newOptionText.value = "";
      }
    };

    const removeOption = (index: number) => {
      newQuestion.value.options.splice(index, 1);
    };

    const removeQuestion = (index: number) => {
      interviewQuestions.value.splice(index, 1);
      emit("updateQuestions", interviewQuestions.value);
    };

    const closeModal = () => {
      showModal.value = false;
    };

    return {
      showModal,
      newQuestion,
      newOptionText,
      interviewQuestions,
      addQuestion,
      addOption,
      removeOption,
      removeQuestion,
      closeModal,
      QuestionTypes,
    };
  },
});
</script>

<style scoped>
.modal {
  z-index: 1050;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.list-group-item div {
  max-width: 100%;
  word-break: break-word;
  overflow-wrap: break-word;
}

li {
  word-wrap: break-word;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.list-group-item button {
  flex-shrink: 0;
  margin-left: auto;
}

.question-text,
.question-type {
  margin-bottom: 6px;
  padding-right: 60px;
}

.question-text strong,
.question-type strong {
  margin-right: 8px;
}

.question-text span,
.question-type span {
  font-weight: normal;
  color: #333;
}

.list-group-item ul {
  margin-top: 6px;
}

.list-group-item {
  position: relative; /* Butonu konumlandırmak için */
  padding: 10px;
  padding-right: 65px; /* Buton için sağda boşluk bırak */
  border: 1px solid #e0e0e0;
  border-radius: 5px;
  margin-bottom: 10px;
  word-wrap: break-word;
  white-space: normal;
  max-width: 100%;
  overflow: hidden;
  word-break: break-word;
  overflow-wrap: break-word;
}

.delete-button {
  position: absolute;
  right: 10px;
  top: 10px; /* Üst köşeye sabitle */
  flex-shrink: 0;
}
</style>
