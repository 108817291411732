
import { computed, defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const interviewTitle = ref("");
    const interviewDesc = ref("");
    const showCustomQuestionInput = ref(false);
    const textAreaHeight = ref(66); 

    const errors = ref<string[]>([]);

    const updateTitleDescription = () => {
      const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
      if (textarea) {
        textarea.style.height = 'auto'; 
        textAreaHeight.value = textarea.scrollHeight; 
      }
      errors.value = [];
      if (interviewDesc.value.length > 500) {
        errors.value.push("Açıklama 500 karakterden fazla olamaz.");
        interviewDesc.value = interviewDesc.value.slice(0, 500);
      }
      if (interviewTitle.value.length > 120) {
        errors.value.push("Başlık 120 karakterden fazla olamaz.");
        interviewTitle.value = interviewTitle.value.slice(0, 120);
      }

      emit("updateTitleDescription", {
        title: interviewTitle.value,
        description: interviewDesc.value,
      });
    };

    const remainingDescChars = computed(() => {
      return 500 - interviewDesc.value.length;
    });

    const remainingTitleChars = computed(() => {
      return 120 - interviewTitle.value.length;
    });


    return {
      showCustomQuestionInput,
      updateTitleDescription,
      interviewTitle,
      interviewDesc,
      remainingDescChars,
      remainingTitleChars,
      errors,
      textAreaHeight,
    };
  },
});
