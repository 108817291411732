
import { computed, defineComponent, onMounted, reactive, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import * as Yup from "yup";
import { useForm } from "vee-validate";
import { setCurrentPageTitle } from "@/presentation/helper/Breadcrumb";
import { StepperComponent } from "@/presentation/assets/ts/components";
import { useI18n } from "vue-i18n";
import Step1 from "./Step1.vue";
import Step2 from "./Step2.vue";
import Step3 from "./Step3.vue";
import Step4 from "./Step4.vue";
import Step5 from "./Step5.vue";
import { CreateInterviewModel } from "@/domain/interviewer/create-interview/model/CreateInterviewModel";
import { InterviewQuestionModel } from "@/domain/interviewer/model/InterviewQuestionsModel";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";
import store from "@/presentation/store";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";

interface IStep1 {
  jobPostingId: number;
}

interface IStep2 {
  interviewTitle: string;
  interviewDesc: string;
}

interface IStep3 {
  interviewQuestions: InterviewQuestionModel[];
}

interface CreateInterview extends IStep1, IStep2, IStep3 {}

export default defineComponent({
  name: "kt-vertical-wizard",
  components: {
    Step1,
    Step2,
    Step3,
    Step4,
    Step5,
  },
  setup() {
    const { t } = useI18n();
    const _stepperObj = ref<StepperComponent | null>(null);
    const verticalWizardRef = ref<HTMLElement | null>(null);
    const currentStepIndex = ref(0);
    const createInterviewModel = ref<CreateInterviewModel>({});
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();

    const formState = reactive({
      jobPosting: null,
      interviews: [],
    });

    const formData = ref<CreateInterview>({
      jobPostingId: 0,
      interviewTitle: "",
      interviewDesc: "",
      interviewQuestions: [],
    });

    onMounted(() => {
      _stepperObj.value = StepperComponent.createInsance(
        verticalWizardRef.value as HTMLElement
      );
      setCurrentPageTitle(t("modals.interview.title"));
    });

    const createInterviewSchema = [
      Yup.object({
        jobPostingId: Yup.number()
          .required("İş ilanı seçilmesi zorunludur.")
          .notOneOf([0], "Lütfen iş ilanı seçiniz."),
      }),
      Yup.object({
        interviewTitle: Yup.string()
          .required(t("validators_error.required"))
          .label("İnterview Title"),
        interviewDesc: Yup.string()
          .required(t("validators_error.required"))
          .label("İnterview Description"),
      }),
    ];

    const currentSchema = computed(() => {
      return createInterviewSchema[currentStepIndex.value];
    });

    const { resetForm, handleSubmit } = useForm<IStep1 | IStep2 | IStep3>({
      validationSchema: currentSchema,
    });

    const totalSteps = computed(() => {
      if (!_stepperObj.value) {
        return;
      }

      return _stepperObj.value.totatStepsNumber;
    });

    resetForm({
      values: {
        ...formData.value,
      },
    });

    const handleStep = handleSubmit((values) => {
      formData.value = {
        ...formData.value,
        ...values,
      };

      currentStepIndex.value++;

      if (currentStepIndex.value == 4) {
        submitForm();
      }

      if (!_stepperObj.value) {
        return;
      }

      _stepperObj.value.goNext();
    });

    const previousStep = () => {
      if (!_stepperObj.value) {
        return;
      }

      currentStepIndex.value--;

      _stepperObj.value.goPrev();
    };

    const formSubmit = () => {
      Swal.fire({
        text: "All is cool! Now you submit this form",
        icon: "success",
        buttonsStyling: false,
        confirmButtonText: "Ok, got it!",
        customClass: {
          confirmButton: "btn fw-bold btn-light-primary",
        },
        heightAuto: false,
      }).then(() => {
        window.location.reload();
      });
    };

    const updateJobPosting = (jobPosting) => {
      createInterviewModel.value.jobPosting = jobPosting;
      store.dispatch("choosenJobPostingIdOnCreateInterviewPage", jobPosting);
      formState.jobPosting = jobPosting;
    };

    const updateTitleDescription = ({ title, description }) => {
      createInterviewModel.value.title = title;
      createInterviewModel.value.description = description;
    };

    const updateQuestions = (questions: InterviewQuestionModel[]) => {
      createInterviewModel.value.interviewQuestions = questions;
      formData.value.interviewQuestions = questions;
    };

    const updateSequences = (sequences: InterviewSequenceModel[]) => {
      createInterviewModel.value.interviewSequences = sequences;
    };

    const submitForm = () => {
      interviewerController
        .createInterview(createInterviewModel.value)
        .then((response) => {
          if (response.isSuccess) {
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
    };

    return {
      verticalWizardRef,
      previousStep,
      handleStep,
      formSubmit,
      totalSteps,
      currentStepIndex,
      updateJobPosting,
      updateTitleDescription,
      updateQuestions,
      updateSequences,
      submitForm,
      createInterviewModel,
      formData,
    };
  },
});
