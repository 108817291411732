<template>
  <div class="w-100" @dragover.prevent="preventScroll">
    <!--begin::Heading-->
    <div class="pb-10 pb-lg-12">
      <h2 class="fw-bolder text-dark">{{ $t("modals.interview.createJobPostingWizard.sortInterviews") }}</h2>
      <div class="text-gray-400 fw-bold fs-6">
        {{ $t("modals.interview.createJobPostingWizard.step4Desc") }}
      </div>
    </div>

    <!-- Mülakatları sıralama alanı -->
    <div class="d-grid gap-4 grid-columns-4">
      <div
        v-if="interviews && interviews.length > 0"
        v-for="(interview, index) in interviews"
        :key="interview.id"
        class="interview-card"
        :class="{
          dragging: index === draggedIndex,
          'drag-over': index === dragOverIndex,
        }"
        draggable="true"
        @dragstart="onDragStart(index)"
        @dragover.prevent="onDragOver(index)"
        @dragleave="onDragLeave"
        @dragend="onDragEnd"
        @drop="onDrop(index)"
      >
        <div class="interview-content">
          <div class="interview-order">{{ index + 1 }}</div>
          <h3 class="fw-bold" v-if="interview.title">{{ interview.title.length > 80 ? interview.title.substring(0, 77) + '...' : interview.title }}</h3>
          <p class="text-gray-500" v-if="interview.description">{{ interview.description.length > 80 ? interview.description.substring(0, 77) + '...' : interview.description }}</p>

        </div>
      </div>
      <!-- <div class="interview-card">
        <div class="interview-content">
          <div class="interview-order"> {{ interviewCount + 1 }} </div>
          <h3 class="fw-bold">Yeni Mülakatın Başlığı</h3>
          <p class="text-gray-500">Yeni Mülakatın Açıklaması</p>
        </div>
        <span class="badge badge-light-success">Yeni</span>
      </div> -->
    </div>
  </div>
</template>

<script lang="ts">
import { SWAL_MESSAGES } from "@/core/constants/SwalMessages";
import { InterviewSequenceModel } from "@/domain/interviewer/model/InterviewSequencesModel";
import { JobPostingInterviewModel } from "@/domain/interviewer/model/JobPostingInterviewModel";
import SwalNotification from "@/presentation/plugins/SwalNotification";
import store from "@/presentation/store";
import { computed, defineComponent, ref, watch, watchEffect } from "vue";
import { useI18n } from "vue-i18n";
import { JobPostingDetailModel } from "@/domain/interviewer/job-posting-detail/model/JobPostingDetailModel";

export default defineComponent({
  name: "step-4",
  props: {
    jobPostingId: {
      type: Number,
      required: false,
    },
    interviewTitle: {
      type: String,
      required: false,
    },
    interviewDesc: {
      type: String,
      required: false,
    },
  },
  setup(props, { emit }) {
    const interviewerController =
      store.state.ControllersModule.interviewerController;
    const swalNotification = new SwalNotification();
    const { t } = useI18n();
    const interviewSequence = ref<InterviewSequenceModel[]>();
    const jobPostingId = ref<number| undefined>(props.jobPostingId);
      const interviewTitle = computed(() => props.interviewTitle);
      const interviewDesc = computed(() => props.interviewDesc);

    const jobPosting = ref<JobPostingDetailModel>({
    });

    const interviews = ref<JobPostingInterviewModel[]>([]);

    const newInterview = ref({
    title: interviewTitle.value,
    description: interviewDesc.value,
    sequence: 0,
  });

  watch([interviewTitle, interviewDesc], () => {
    newInterview.value = {
      title: interviewTitle.value,
      description: interviewDesc.value,
      sequence: interviews.value.length, 
    };
    getJobPosting()

  });

    const getJobPosting = () => {
      interviews.value = []
      if(jobPostingId.value != 0 && jobPostingId.value != undefined && jobPostingId.value){
      const jobPostingDetailModel: JobPostingDetailModel = {
        id: jobPostingId.value,
      };

      interviewerController
        .jobPostingDetail(jobPostingDetailModel)
        .then((response) => {
          if (response.isSuccess) {
            Object.assign(jobPosting.value, response.getValue())
            if(jobPosting.value.interviews)
        
            Object.assign(interviews.value, jobPosting.value.interviews);
            newInterview.value.sequence = interviews.value.length;

            interviews.value.push(newInterview.value);

            interviewSequence.value = interviews.value.map(
              (interview, idx) =>
                new InterviewSequenceModel(interview.id ?? null, idx + 1)
            );

            emit("updateSequences", interviewSequence);
          } else {
            swalNotification.error(
              response.error.cause.cause +
                t(SWAL_MESSAGES.ERROR.ERROR_CODE) +
                response.error.code +
                response.error.cause.code,
              t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT)
            );
          }
        })
        .catch((e) => {
          swalNotification.error(e, t(SWAL_MESSAGES.CONFIRM_BUTTON_TEXT));
        });
      }
    };

    watch(
      () => store.getters.choosenJobPostingIdOnCreateInterviewPage,
      (newValue) => {
        if (newValue !== null && newValue !== undefined) {
          jobPostingId.value = Number(newValue);
          getJobPosting();
        }
      },
      { immediate: true }
    );

    const draggedIndex = ref<number | null>(null);
    const dragOverIndex = ref<number | null>(null);

    const onDragStart = (index: number) => {
      draggedIndex.value = index;
    };

    const onDragOver = (index: number) => {
      dragOverIndex.value = index;
    };

    const onDragLeave = () => {
      dragOverIndex.value = null;
    };

    const onDrop = (index: number) => {
      if (draggedIndex.value !== null && draggedIndex.value !== index) {
        const draggedItem = interviews.value[draggedIndex.value];
        interviews.value.splice(draggedIndex.value, 1);
        interviews.value.splice(index, 0, draggedItem);
        const updatedSequences = interviews.value.map((interview, idx) => {
          return new InterviewSequenceModel(interview.id, idx + 1);
        });

        // Emit edilen model
        emit("updateSequences", updatedSequences);
      }
      cleanDragState();
    };

    const onDragEnd = () => {
      cleanDragState();
    };

    const cleanDragState = () => {
      draggedIndex.value = null;
      dragOverIndex.value = null;
    };

    const preventScroll = (event: DragEvent) => {
      event.preventDefault();
    };

    return {
      interviews,
      onDragStart,
      onDragOver,
      onDragLeave,
      onDrop,
      onDragEnd,
      preventScroll,
      draggedIndex,
      dragOverIndex,
      jobPosting,
      newInterview,
    };
  },
});
</script>

<style scoped>
/* Grid Layout */
.d-grid {
  display: grid;
  gap: 16px;
}

.grid-columns-4 {
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
}

.interview-card {
  position: relative;
  background-color: #f5f5f5;
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 16px;
  text-align: center;
  cursor: grab;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
  box-sizing: border-box;
  transition: none;
}

/* Sürükleme Sırasında Stil */
.dragging {
  opacity: 0.5;
}

.drag-over {
  outline: 2px dashed #007bff;
  background-color: #e2e6ea;
}

.interview-content {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  gap: 8px;
  height: 100%;
  width: 100%;
  text-align: center;
  padding-left: 30px;
  padding-right: 30px;
}

.interview-card h3 {
  font-size: 16px;
  font-weight: bold;
  word-break: break-word; /* Uzun kelimelerin bölünmesini sağlar */
  text-align: center;
}

.interview-card p {
  font-size: 14px;
  color: #666;
  word-break: break-word; /* Metin taşmasını önler */
  overflow-wrap: break-word; /* Alternatif taşma önleme */
  text-align: center;
  flex-grow: 1; /* Açıklama alanının esnek büyümesini sağlar */
  display: flex;
  align-items: center; /* Dikey hizalama */
  justify-content: center;
}

/* Sıra İbaresi */
.interview-order {
  position: absolute;
  top: 8px;
  left: 8px;
  background-color: #007bff;
  color: #fff;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 16px;
}
</style>
