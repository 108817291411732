<template>
  <div class="w-100">
    <!-- Başlık -->
    <div class="pb-10 pb-lg-15">
      <h2 class="fw-bolder text-dark">
        {{ $t("modals.interview.createJobPostingWizard.createInterview") }}
      </h2>
      <div class="text-gray-400 fw-bold fs-6">
        {{ $t("modals.interview.createJobPostingWizard.defineQuestions") }}
      </div>
    </div>

    <!-- Mülakat Başlığı -->
    <div class="mb-10 fv-row">
      <label class="form-label mb-3">{{
        $t("modals.interview.interviewTitle")
      }}</label>
       <div class="position-relative">
      <Field
        type="text"
        class="form-control form-control-lg form-control-solid"
        :style="{ paddingRight: '55px' }"
        name="interviewTitle"
        v-model="interviewTitle"
        maxlength="120"
        @change="updateTitleDescription"
        :placeholder="$t('modals.interview.titlePlaceholder')"
      />
      <div
          class="character-count position-absolute end-0 top-0 pe-2 pt-2"
          :class="{'text-danger': remainingTitleChars === 0}"
          v-show="remainingTitleChars >= 0"
        >
          {{ remainingTitleChars }}/150
        </div>
      <ErrorMessage
        name="interviewTitle"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>
    </div>

    <!-- Mülakat Açıklaması -->
    <div class="mb-10 fv-row">
      <label class="form-label mb-3">{{
        $t("modals.interview.description")
      }}</label>
      <div class="position-relative">
        <Field
          as="textarea"
          type="text"
          class="form-control form-control-lg form-control-solid overflow-hidden"
          :style="{ paddingRight: '55px', height: textAreaHeight + 'px' }"
          name="interviewDesc"
          v-model="interviewDesc"
          @input="updateTitleDescription"
          maxlength="500"
          :placeholder="$t('modals.interview.descriptionPlaceholder')"
        />
        <div
          class="character-count position-absolute end-0 top-0 pe-2 pt-2"
          :class="{'text-danger': remainingDescChars === 0}"
          v-show="remainingDescChars >= 0"
        >
          {{ remainingDescChars }}/500
        </div>
      </div>
      <ErrorMessage
        name="interviewDesc"
        class="fv-plugins-message-container invalid-feedback"
      />
    </div>

    <div v-if="errors.length > 0" class="mt-3 mb-7 alert alert-warning">
      <ul>
        <li v-for="(error, index) in errors" :key="index">
          {{ error }}
        </li>
      </ul>
    </div>

    <!-- Mülakat Türü Seç -->
    <!-- <div class="mb-0 fv-row">
      <label class="d-flex align-items-center form-label mb-5">
        Mülakat Türü Seç
        <i
          class="fas fa-exclamation-circle ms-2 fs-7"
          data-bs-toggle="tooltip"
          title="Mülakat türünü özelleştirip mülakatlarınızı gerçekleştirebilirsiniz."
        ></i>
      </label>

      <div class="mb-0">
        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="/media/icons/duotune/finance/fin001.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Özel Mülakat</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Tamamen özelleştirilmiş sorular ile mülakat oluşturun.</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="1"
              @change="showCustomQuestionInput = true"
            />
          </span>
        </label>

        <label class="d-flex flex-stack mb-5 cursor-pointer">
          <span class="d-flex align-items-center me-2">
            <span class="symbol symbol-50px me-6">
              <span class="symbol-label">
                <span class="svg-icon svg-icon-1 svg-icon-gray-600">
                  <inline-svg src="/media/icons/duotune/graphs/gra006.svg" />
                </span>
              </span>
            </span>
            <span class="d-flex flex-column">
              <span class="fw-bolder text-gray-800 text-hover-primary fs-5"
                >Star Metodolojisi</span
              >
              <span class="fs-6 fw-bold text-gray-400"
                >Star metodolojisi ile adayınızdan kendini tanıtmasını ve
                mesleğe bakış açısını anlatmasını isteyin</span
              >
            </span>
          </span>
          <span class="form-check form-check-custom form-check-solid">
            <Field
              class="form-check-input"
              type="radio"
              name="accountPlan"
              value="2"
              @change="showCustomQuestionInput = false"
            />
          </span>
        </label>
      </div>
    </div> -->
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { Field, ErrorMessage } from "vee-validate";

export default defineComponent({
  name: "step-2",
  components: {
    Field,
    ErrorMessage,
  },
  setup(props, { emit }) {
    const interviewTitle = ref("");
    const interviewDesc = ref("");
    const showCustomQuestionInput = ref(false);
    const textAreaHeight = ref(66); 

    const errors = ref<string[]>([]);

    const updateTitleDescription = () => {
      const textarea = document.querySelector('textarea') as HTMLTextAreaElement;
      if (textarea) {
        textarea.style.height = 'auto'; 
        textAreaHeight.value = textarea.scrollHeight; 
      }
      errors.value = [];
      if (interviewDesc.value.length > 500) {
        errors.value.push("Açıklama 500 karakterden fazla olamaz.");
        interviewDesc.value = interviewDesc.value.slice(0, 500);
      }
      if (interviewTitle.value.length > 120) {
        errors.value.push("Başlık 120 karakterden fazla olamaz.");
        interviewTitle.value = interviewTitle.value.slice(0, 120);
      }

      emit("updateTitleDescription", {
        title: interviewTitle.value,
        description: interviewDesc.value,
      });
    };

    const remainingDescChars = computed(() => {
      return 500 - interviewDesc.value.length;
    });

    const remainingTitleChars = computed(() => {
      return 120 - interviewTitle.value.length;
    });


    return {
      showCustomQuestionInput,
      updateTitleDescription,
      interviewTitle,
      interviewDesc,
      remainingDescChars,
      remainingTitleChars,
      errors,
      textAreaHeight,
    };
  },
});
</script>

<style scoped>
.list-group-item {
  border: none;
  padding: 10px 15px;
  background-color: #f8f9fa;
  margin-bottom: 5px;
  border-radius: 4px;
}

.character-count {
  font-size: 12px;
  color: #6c757d;
  margin-top: 0;
  position: absolute;
  right: 10px; 
  top: 10px;
  z-index: 1;
  padding: 2px 4px;
  border-radius: 4px;
}

textarea {
  resize: none;  
}
</style>
